import { addPropertyControls } from "framer"

import {
    GoogleReviewsCardGrid,
    propertyControls,
} from "https://scripts.feedspring.co/google-reviews-framer/card-grid.js#010"

addPropertyControls(FeedspringGoogleReviewsCardGrid, propertyControls)

export default function FeedspringGoogleReviewsCardGrid(props) {
    return <GoogleReviewsCardGrid settings={props} />
}
